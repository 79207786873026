import React, { useState } from "react";
import { Form, Input, Modal, Spin } from "antd";
import { json, Link, useHistory } from "react-router-dom";
import "./style.css";
import axios from "axios";

const VerifyModal = ({ codeModal, setCodeModal, userId }) => {
  const history = useHistory();
  const data = localStorage.getItem("user");
  const email = JSON.parse(data)?.email;
  const [loader, setLoader] = useState(false);

  const [form] = Form.useForm();
  const handleSubmitCode = async (values) => {
    let result = await axios.post(
      // "http://localhost:8000/api/users/emailVerify",
      "https://api.castingcostadvisor.com/api/users/emailVerify",
      values
    );
    if (result.status == 200) {
      history.push("/login");
    }
  };

  const handleResendSendCode = async () => {
    setLoader(true);
    let result = await axios.post(
      // "http://localhost:8000/api/users/resendEmailVerify",
      "https://api.castingcostadvisor.com/api/users/resendEmailVerify",
      { id: userId }
    );
    if (result.status === 200) {
      console.log("token send successfully");
      setLoader(false);
    }
  };

  return (
    <div>
      <Modal
        visible={codeModal}
        title="Email Verification"
        okText="Submit"
        cancelText="Cancel"
        onCancel={() => setCodeModal(false)}
        maskClosable={false}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              handleSubmitCode(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          className="verify_modal"
        >
          <Form.Item
            name="token"
            rules={[
              {
                required: true,
                message: "Please input the Code",
              },
            ]}
          >
            <Input placeholder="Enter Verification Code" />
          </Form.Item>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>Didn't get code?</div>
            {loader ? (
              <Spin style={{ marginLeft: "6px" }} />
            ) : (
              <Link
                to="#"
                onClick={() => handleResendSendCode()}
                style={{ marginLeft: "0.3rem" }}
              >
                resend
              </Link>
            )}
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default VerifyModal;
